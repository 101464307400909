<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','content']"
            ref="list"
            :submit_preprocessing="submit_preprocessing"
        >
			<template slot="right_btn">
                <a :href="'/manage/crm.export/daily' | export_form" v-if="this.$store.state.page_auth.export" target="_blank">
					<a-button  class="a_btn">导出</a-button>
				</a>
            </template>
           <template slot="content" slot-scope="data">
                <span v-if="data.text" v-text="data.text.length <= 50 ? data.text : data.text.slice(0,50) + '...'"></span>
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="get_details(data)">查看详情</a>
            </template>
        </TableList>

        <RightDrawer title :visible.sync="drawer_visible">
            <template slot="content">
                <UserHeader
                    :head_img="details.headimg"
                    :name="details.username"
                    :info="details.create_time"
                ></UserHeader>
                <a-divider />
                <a-descriptions>
                    <a-descriptions-item :span="3" label="部门">{{details.department}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="岗位职务">{{details.role}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="留言时间">{{details.feedback_time}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="留言内容问题">{{details.content}}</a-descriptions-item>
                    <a-descriptions-item :span="3" label="图片"></a-descriptions-item>
                </a-descriptions>
                <LookImages :list="details.image"></LookImages>
            </template>
        </RightDrawer>
    </div>
</template>

<script>

import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import RightDrawer from "@/components/RightDrawer";
import UserHeader from "@/components/UserHeader";
import LookImages from "@/components/LookImages";

import { getComplaintList, getComplaintInfo } from "@/api/attendance";

const columns = [
    {
        title: "留言时间",
        dataIndex: "feedback_time",
    },
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "department",
    },
	{
	    title: "直系领导",
	    dataIndex: "superior",
	},
	{
	    title: "留言标题",
	    dataIndex: "title",
	},
    {
        title: "留言内容",
        dataIndex: "content",
		scopedSlots: {
		    customRender: "content"
		}
    },
   
    {
        title: "创建日期",
        dataIndex: "create_time",
        width: '200px'
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList,
        RightDrawer,
        UserHeader,
        LookImages
    },
    data() {
        return {
			get_table_list: getComplaintList,
			config:this.$config,
            submit_preprocessing: {
                array_to_string: ['department']
            },
            details: {
                goods: [],
            },
            columns,
            drawer_visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "姓名",
                        placeholder: '姓名/账号/工号/手机号',
                        mode: "default",
                        options: {},
                        list: []
					},

					{
                        type: "select",
                        name: "user_status",
                        title: "在职状态",
                        mode: "default",
                        options: {},
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "留言日期",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
        });
    },
    methods: {
        get_details(item, index) {
            getComplaintInfo({
                data: {
                    id: item.record.id
                }
            }).then(res => {
                this.details = res.data;
                this.show();
            })
        },
        show() {
            this.drawer_visible = true;
        },
        drawer_visible_close() {
            this.drawer_visible = false;
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>